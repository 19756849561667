<template>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" class="el-form-item"
             style="width:800px">
        <el-row>
            <el-form-item label="收集号" prop="collectNumber">
                <el-input v-model="form.collectNumber" placeholder="请输入收集号"/>
            </el-form-item>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="作(植)物类别">
                    <el-input v-model="form.type" placeholder="请选择作(植)物类别"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="作(植)物名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入作(植)物名称"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="种质名称" prop="germplasmName">
                    <el-input v-model="form.germplasmName" placeholder="请输入种质名称"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="种质外文名称" prop="germplasmNameEn">
                    <el-input v-model="form.germplasmNameEn" placeholder="请输入种质外文名称"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="科名" prop="sectionName">
                    <el-input v-model="form.sectionName" placeholder="请输入科名"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="属名" prop="genericName">
                    <el-input v-model="form.genericName" placeholder="请输入属名"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="学名" prop="scientificName">
                    <el-input v-model="form.scientificName" placeholder="请输入学名"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="种质资源类型">
                    <el-input v-model="form.resourceType" placeholder="请选择种质资源类型"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="收集方式" prop="collectMethod">
                    <el-input v-model="form.collectMethod" placeholder="请输入收集方式"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="种质来源" prop="germplasmSource">
                    <el-input v-model="form.germplasmSource" placeholder="请输入种质来源"/>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="来源国" prop="sourceCountry">
                    <el-input v-model="form.sourceCountry" placeholder="请输入来源国"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="来源省(州、邦)" prop="sourceProvince">
                    <el-input v-model="form.sourceProvince" placeholder="请输入来源省(州、邦)"/>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="来源地" prop="source">
                    <el-input v-model="form.source" placeholder="请输入来源地"/>
                </el-form-item>

            </el-col>
            <el-col :span="12">
                <el-form-item label="来源机构" prop="sourceOrg">
                    <el-input v-model="form.sourceOrg" placeholder="请输入来源机构"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="原产国" prop="originCountry">
                    <el-input v-model="form.originCountry" placeholder="请输入原产国"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="原产地" prop="originPalce">
                    <el-input v-model="form.originPalce" placeholder="请输入原产地"/>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="收集地经度" prop="collectPlaceLongitude">
                    <el-input v-model="form.collectPlaceLongitude" placeholder="请输入收集地经度"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="收集地纬度" prop="collectPlaceLatitude">
                    <el-input v-model="form.collectPlaceLatitude" placeholder="请输入收集地纬度"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="收集地海拔" prop="collectPalceAltitude">
                    <el-input v-model="form.collectPlaceAltitude" placeholder="请输入收集地海拔"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="收集地土壤类型">
                    <el-input v-model="form.collectPlaceSoilType" placeholder="请选择收集地土壤类型"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="收集地生态类型">
                    <el-input v-model="form.collectPlaceEcologyType" placeholder="请选择收集地生态类型"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="收集材料类型">
                    <el-input v-model="form.collectMaterialType" placeholder="请选择收集材料类型"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="收集人" prop="collectPeople">
                    <el-input v-model="form.collectPeople" placeholder="请输入收集人"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="收集单位" prop="collectUnit">
                    <el-input v-model="form.collectUnit" placeholder="请输入收集单位"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="收集时间" prop="collectTime">
                    <el-input v-model="form.collectTime" placeholder="请输入收集时间"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">

                <el-form-item label="项目归口" prop="projectAlley">
                    <el-input v-model="form.projectAlley" placeholder="请输入项目归口"/>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>

            <el-form-item label="原生境图片" prop="img">
                <el-input v-model="form.projectAlley" placeholder="请输入原生境图片"/>
            </el-form-item>
        </el-row>

        <el-row style="margin-right: 0px;background: #e6a23c">
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <!--            <el-button @click="reset" v-if="!update">重 置</el-button>-->
            <!--            <el-button @click="cancel" v-if="update">取 消</el-button>-->
        </el-row>


    </el-form>
</template>

<script>
    export default {
        name: "resources-data-collect",
        data() {
            return {
                form: {
                    collectNumber:undefined,
                    type:undefined,
                    name:undefined,
                    germplasmName:undefined,
                    germplasmNameEn:undefined,
                    sectionName:undefined,
                    genericName:undefined,
                    scientificName:undefined,
                    resourceType:undefined,
                    collectMethod:undefined,
                    germplasmSource:undefined,
                    sourceCountry:undefined,
                    sourceProvince:undefined,
                    source:undefined,
                    sourceOrg:undefined,
                    originCountry:undefined,
                    originPlace:undefined,
                    collectPlaceLongitude:undefined,
                    collectPlaceLatitude:undefined,
                    collectPlaceAltitude:undefined,
                    collectPlaceSoilType:undefined,
                    collectPlaceEcologyType:undefined,
                    collectMaterialType:undefined,
                    collectPeople:undefined,
                    collectUnit:undefined,
                    collectTime:undefined,
                    projectAlley:undefined,
                    img:undefined,
                },
                rules: {},
            }
        },
        methods: {
            /** 提交按钮 */
            submitForm: function () {
                this.loading = true;
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        this.$http({
                            url: this.$http.adornUrl('/resources-collect'),
                            method: 'post',
                            data: this.$http.adornData(this.form, false)
                        }).then(response => {
                            this.loading = false;
                            this.$message.success("数据收集资源增加成功!")
                        });
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .el-form-item {
        margin-bottom: 16px;
    }
</style>
